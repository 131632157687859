<template>
  <div class="row">
    <div class="col-12">
      <Breadcrumb :items="breadcrumbs" />
      <div class="lk">
        <div class="row">
          <div class="col-lg-3">
            <div class="lk-nav">
              <UserBar />
              <SideBar />
            </div>
          </div>
          <div class="col-lg-9">

            <div class="lk__header lk__header--orders">
              <div class="title title--secondary">
                Мои заказы
              </div>

              <div class="period">
                <div class="period__title">Период:</div>
                <div class="period__items">
                  <div class="period__item">
                    <button class="period__item-btn">
                      <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.12659 6.3293L5.26514 11.8643C5.4335 12.0455 5.70608 12.0452 5.87415 11.8634C6.0421 11.6815 6.04167 11.3869 5.87329 11.2055L1.0406 5.99998L5.87346 0.794435C6.04182 0.613029 6.04225 0.318632 5.87433 0.136757C5.79007 0.0455856 5.67969 -1.70549e-08 5.56931 -2.29321e-08C5.45921 -2.87943e-08 5.34927 0.0452804 5.26517 0.135819L0.12659 5.67068C0.0455026 5.75782 8.78598e-07 5.87642 8.6087e-07 5.99998C8.43142e-07 6.12354 0.0456327 6.24199 0.12659 6.3293Z" fill="#24ABE2"/>
                      </svg>
                    </button>
                    <div class="period__item-title">
                      21 Апрель 2021
                    </div>
                    <button class="period__item-btn">
                      <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.87341 5.6707L0.734858 0.1357C0.566499 -0.0455414 0.293923 -0.0452367 0.125846 0.136638C-0.0421 0.318488 -0.041666 0.613073 0.126714 0.794456L4.9594 6.00002L0.126541 11.2056C-0.041818 11.387 -0.0422519 11.6814 0.125673 11.8632C0.209928 11.9544 0.320308 12 0.430689 12C0.540787 12 0.650733 11.9547 0.734837 11.8642L5.87341 6.32932C5.9545 6.24218 6 6.12358 6 6.00002C6 5.87646 5.95437 5.75801 5.87341 5.6707Z" fill="#24ABE2"/>
                      </svg>
                    </button>
                  </div>
                  <div class="period__item">
                    <button class="period__item-btn">
                      <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.12659 6.3293L5.26514 11.8643C5.4335 12.0455 5.70608 12.0452 5.87415 11.8634C6.0421 11.6815 6.04167 11.3869 5.87329 11.2055L1.0406 5.99998L5.87346 0.794435C6.04182 0.613029 6.04225 0.318632 5.87433 0.136757C5.79007 0.0455856 5.67969 -1.70549e-08 5.56931 -2.29321e-08C5.45921 -2.87943e-08 5.34927 0.0452804 5.26517 0.135819L0.12659 5.67068C0.0455026 5.75782 8.78598e-07 5.87642 8.6087e-07 5.99998C8.43142e-07 6.12354 0.0456327 6.24199 0.12659 6.3293Z" fill="#24ABE2"/>
                      </svg>
                    </button>
                    <div class="period__item-title">
                      17 Май 2021
                    </div>
                    <button class="period__item-btn">
                      <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.87341 5.6707L0.734858 0.1357C0.566499 -0.0455414 0.293923 -0.0452367 0.125846 0.136638C-0.0421 0.318488 -0.041666 0.613073 0.126714 0.794456L4.9594 6.00002L0.126541 11.2056C-0.041818 11.387 -0.0422519 11.6814 0.125673 11.8632C0.209928 11.9544 0.320308 12 0.430689 12C0.540787 12 0.650733 11.9547 0.734837 11.8642L5.87341 6.32932C5.9545 6.24218 6 6.12358 6 6.00002C6 5.87646 5.95437 5.75801 5.87341 5.6707Z" fill="#24ABE2"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div class="catalog__head-sort">
                <span>Сортировать по статусу:</span>
                <select class="custom-select catalog__head-select custom-select--w-auto">
                  <option value="">Все</option>
                  <option value="">В Пути</option>
                  <option value="">Выполнен</option>
                  <option value="">Принято</option>
                </select>
              </div>

            </div>

            <div class="products-list">
              <Item />
            </div>

            <button class="btn btn--show-more btn--top-indent">
              <svg class="icon-svg btn--show-more__icon"><use xlink:href="@/assets/img/sprite.svg#load-more"></use></svg>
              Посмотреть еще
            </button>
            <!--<b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="rows"
              :per-page="perPage"
              :ellipsis-class="[pageClass, pageClassEllipsis]"
              :page-class="pageClass"
              :next-class="[pageClass, pageClassArrow]"
              :prev-class="[pageClass, pageClassArrow]"
              first-class="d-none"
              last-class="d-none"
            >
              <template #next-text>
                <svg width="8" height="15" class="icon-svg" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.18758 1.25L7.41675 7.47917L1.18758 13.7083" stroke="currentColor" stroke-width="0.958333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </template>
              <template #prev-text>
                <svg width="8" height="15" class="icon-svg" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.43742 1.25L1.20825 7.47917L7.43742 13.7083" stroke="currentColor" stroke-width="0.958333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </template>
            </b-pagination>-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <Callback />
</template>
<script>
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import Callback from '~/pages/account/partials/callback'
import UserBar from '~/pages/account/partials/userBar'
import SideBar from '~/pages/account/partials/sidebar'
import Item from './partials/item'
export default {
  components: {
    Callback,
    UserBar,
    SideBar,
    Item
  },
  setup () {
    const { t } = useI18n()
    useMeta({
      title: t('orders')
    })
    return {
      t,
      breadcrumbs: [
        { name: t('home'), href: '/' },
        { name: t('orders'), href: null, active: true }
      ]
    }
  }
}
</script>
